/**
 * State
 */

export interface CreateBucketsState {
  buckets: Record<
    number,
    {
      name: string;
      description: string;
      minSelection: number;
      maxSelection: number;
      selectionWindowStartsAtUtc: Date;
      selectionWindowEndsAtUtc: Date;
      preexistingMatchupIds: number[];
      isDeleted: boolean;
      errors: Record<string, string>;
    }
  >;
  currentIndex: number;
}

export const initialCreateBucketsState: CreateBucketsState = {
  buckets: {},
  currentIndex: 0,
};

/**
 * Actions
 */

export interface UpdateBucketAction {
  type: "UPDATE_FIELD";
  payload: {
    bucketId: number;
    field:
      | "name"
      | "description"
      | "minSelection"
      | "maxSelection"
      | "selectionWindowStartsAtUtc"
      | "selectionWindowEndsAtUtc";
    value: string | number | Date;
  };
}

export interface MatchupClicked {
  type: "MATCHUP_CLICKED";
  payload: {
    matchupId: number;
  };
}

export interface UpdateCurrentBucketIndex {
  type: "UPDATE_CURRENT_INDEX";
  payload: {
    newIndex: number;
  };
}

export interface DeleteBucket {
  type: "DELETE_BUCKET";
  payload: {
    bucketIndex: number;
  };
}

export interface CreateNewBucket {
  type: "CREATE_NEW_BUCKET";
}

export interface ClearBucketErrors {
  type: "CLEAR_BUCKET_ERRORS";
}

export interface ResetBucketState {
  type: "RESET_BUCKET_STATE";
}

export type CreateBucketActions =
  | MatchupClicked
  | UpdateBucketAction
  | UpdateCurrentBucketIndex
  | CreateNewBucket
  | DeleteBucket
  | ClearBucketErrors
  | ResetBucketState;

/**
 * Reducer
 */

export const createBucketsReducer = (
  state: CreateBucketsState,
  action: CreateBucketActions,
) => {
  switch (action.type) {
    case "UPDATE_FIELD": {
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.bucketId]: {
            ...state.buckets[action.payload.bucketId],
            [action.payload.field]: action.payload.value,
          },
        },
      };
    }
    case "MATCHUP_CLICKED": {
      const newMatchupIdsArray = [
        ...state.buckets[state.currentIndex].preexistingMatchupIds,
      ];
      const selectedMatchupIndex = newMatchupIdsArray.indexOf(
        action.payload.matchupId,
      );
      if (selectedMatchupIndex > -1) {
        newMatchupIdsArray.splice(selectedMatchupIndex, 1);
      } else {
        newMatchupIdsArray.push(action.payload.matchupId);
      }
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [state.currentIndex]: {
            ...state.buckets[state.currentIndex],
            preexistingMatchupIds: newMatchupIdsArray,
          },
        },
      };
    }
    case "UPDATE_CURRENT_INDEX": {
      return {
        ...state,
        currentIndex: action.payload.newIndex,
      };
    }
    case "CREATE_NEW_BUCKET": {
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [Object.values(state.buckets).length]: {
            name: "",
            description: "",
            minSelection: 0,
            maxSelection: 1,
            selectionWindowStartsAtUtc: new Date(),
            selectionWindowEndsAtUtc: new Date(),
            preexistingMatchupIds: [],
            isDeleted: false,
            errors: {},
          },
        },
      };
    }
    case "DELETE_BUCKET": {
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.bucketIndex]: {
            ...state.buckets[action.payload.bucketIndex],
            isDeleted: true,
          },
        },
        currentIndex: Object.keys(state.buckets).findIndex(
          (k) =>
            k !== action.payload.bucketIndex.toString() &&
            !state.buckets[Number.parseInt(k)].isDeleted,
        ),
      };
    }
    case "CLEAR_BUCKET_ERRORS": {
      const newBuckets = { ...state.buckets };
      Object.keys(newBuckets).forEach((bkey) => {
        newBuckets[Number.parseInt(bkey)].errors = {};
      });
      return {
        ...state,
        buckets: newBuckets,
      };
    }
    case "RESET_BUCKET_STATE": {
      return initialCreateBucketsState;
    }
  }
};
