import React from "react";
import * as style from "./CreateContestTemplate.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { toast } from "react-toastify";
import { classes } from "typestyle";
import {
  CreateBuckets,
  createBucketsReducer,
  initialCreateBucketsState,
} from "../CreateBuckets";
import { CreateMatchups } from "../CreateMatchups";
import { CreateMatchupOptions } from "../CreateMatchupOptions";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import { RewardsStoreBrand } from "../../../store/reducers/rewards";
import { Sport } from "../../../models/contest";

interface StateProps {
  rewards: Record<number, Models.Rewards.Reward> | null;
  brands: Record<number, RewardsStoreBrand> | null;
}

export const CreateContestTemplateInternal: React.FC<StateProps> = ({
  rewards,
  brands,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  /**
   * Nav
   */
  const [activeTab, setActiveTab] = React.useState("Details");
  /**
   * Values
   */
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [sport, setSport] = React.useState("");
  const [viewableStartsAtUtc, setViewableStartsAtUtc] =
    React.useState<Date | null>(null);
  const [availableStartsAtUtc, setAvailableStartsAtUtc] =
    React.useState<Date | null>(null);
  const [availableEndsAtUtc, setAvailableEndsAtUtc] =
    React.useState<Date | null>(null);
  // const [minContestants, setMinContestants] = React.useState("");
  // const [maxContestants, setMaxContestants] = React.useState("");
  const [format1v1, setFormat1v1] = React.useState(false);
  const [formatGroup, setFormatGroup] = React.useState(false);
  const [maxContests, setMaxContests] = React.useState("");
  const [tokenEntry, setTokenEntry] = React.useState(false);
  const [freeEntry, setFreeEntry] = React.useState(false);
  const [minTokenEntry, setMinTokenEntry] = React.useState("");
  // const [maxTokenEntry, setMaxTokenEntry] = React.useState("");
  const [rewardId, setRewardId] = React.useState("");
  const [sponsorId, setSponsorId] = React.useState("");
  const [categories, setCategories] = React.useState<string[]>([]);

  /**
   * Error Values
   */
  const [submitError, setSubmitError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [viewableStartsAtUtcError, setViewableStartsAtUtcError] =
    React.useState("");
  const [availableStartsAtUtcError, setAvailableStartsAtUtcError] =
    React.useState("");
  const [availableEndsAtUtcError, setAvailableEndsAtUtcError] =
    React.useState("");
  // const [minContestantsError, setMinContestantsError] = React.useState("");
  // const [maxContestantsError, setMaxContestantsError] = React.useState("");
  const [maxContestsError, setMaxContestsError] = React.useState("");
  const [minTokenEntryError, setMinTokenEntryError] = React.useState("");
  // const [maxTokenEntryError, setMaxTokenEntryError] = React.useState("");
  const [rewardIdError, setRewardIdError] = React.useState("");

  /**
   * State
   */
  const [inProgress, setInProgress] = React.useState(false);
  const [bucketsState, localDispatch] = React.useReducer(
    createBucketsReducer,
    initialCreateBucketsState,
  );
  /**
   * Mutation
   */
  const [createContestTemplate, createContestTemplateStatus] = useMutation(
    Mutation.CREATE_CONTEST_TEMPLATE_MUTATION,
  );

  const rewardsArray = React.useMemo(() => {
    return !!rewards ? Object.values(rewards) : [];
  }, [rewards]);
  const brandsArray = React.useMemo(() => {
    return !!brands ? Object.values(brands) : [];
  }, [brands]);
  const getRewards = (skip?: number) => {
    client
      .query({
        query: Query.GET_REWARDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetRewardsSucces(res.data.adminGetRewards));
      });
  };
  const getBrands = (skip?: number) => {
    client
      .query({
        query: Query.GET_BRANDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetBrandsSuccess(res.data.adminGetBrands));
      });
  };
  React.useEffect(() => {
    getRewards();
    getBrands();
  }, []);

  /**
   * Validation and Submission
   */
  const onSubmit = () => {
    setSubmitError("");
    setNameError("");
    setDescriptionError("");
    setRewardIdError("");
    setMaxContestsError("");
    setViewableStartsAtUtcError("");
    setAvailableEndsAtUtcError("");
    setAvailableStartsAtUtcError("");
    setMinTokenEntryError("");
    localDispatch({ type: "CLEAR_BUCKET_ERRORS" });
    if (!tokenEntry && !freeEntry) {
      setSubmitError(
        "Contest Template must enable token entry, free entry or both",
      );
      return;
    } else if (!!tokenEntry && !minTokenEntry) {
      setMinTokenEntryError(
        "Token Entry contests must include a value for Min Token Entry",
      );
      return;
      // } else if (!!tokenEntry && !maxTokenEntry) {
      //   setMaxTokenEntryError(
      //     "Token Entry contests must include a value for Max Token Entry",
      //   );
      //   return;
    } else if (!!tokenEntry && !rewardId) {
      setRewardIdError("Token Entry contests must include a Reward");
      return;
      // } else if (!!tokenEntry && minTokenEntry > maxTokenEntry) {
      //   setMinTokenEntryError(
      //     "Min Token Entry must be less than max token entry",
      //   );
      //   return;
    } else if (
      !viewableStartsAtUtc ||
      !availableStartsAtUtc ||
      !availableEndsAtUtc
    ) {
      setViewableStartsAtUtcError(
        !viewableStartsAtUtc ? "Must set a date for viewable start time" : "",
      );
      setAvailableStartsAtUtcError(
        !availableStartsAtUtc ? "Must set a date for available start time" : "",
      );
      setAvailableEndsAtUtcError(
        !availableEndsAtUtc ? "Must set a date for available end time" : "",
      );
      return;
    } else if (availableEndsAtUtc < availableStartsAtUtc) {
      setAvailableEndsAtUtcError("Available end time must be after start time");
      return;
      // } else if (!minContestants || Number.parseInt(minContestants) < 1) {
      //   setMinContestantsError("Min Contestants must be at least 1");
      //   return;
      // } else if (!maxContestants || Number.parseInt(maxContestants) < 1) {
      //   setMaxContestantsError("Max Contestants must be at least 1");
      //   return;
      // }
    } else if (!format1v1 && !formatGroup) {
      setSubmitError("Must allow 1v1 contests, Group contests, or both");
      return;
    }
    const bucketsArray = Object.values(bucketsState.buckets).filter(
      (bucket) => !bucket.isDeleted,
    );
    if (bucketsArray.length < 1) {
      setSubmitError("Must have at least 1 Bucket");
      return;
    } else if (
      new Set(bucketsArray.map((bucket) => bucket.name)).size !==
      bucketsArray.length
    ) {
      setSubmitError("All buckets must have unique names");
      return;
    }
    let bucketErrorExists = false;
    bucketsArray
      .filter((bucket) => !bucket.isDeleted)
      .forEach((bucket) => {
        if (!bucket.name) {
          setSubmitError("All buckets must have name");
          bucketErrorExists = true;
          return;
        } else if (
          bucket.selectionWindowEndsAtUtc <= bucket.selectionWindowStartsAtUtc
        ) {
          setSubmitError(
            "Selection Windows for Buckets must end after they start",
          );
          bucketErrorExists = true;
          return;
          // } else if (bucket.maxSelection < 1) {
          //   setSubmitError("Max Selection must be at least 1 for all buckets");
          //   bucketErrorExists = true;
          //   return;
        } else if (bucket.minSelection < 1) {
          setSubmitError("Min Selection must be at least 1 for all buckets");
          bucketErrorExists = true;
          return;
          // } else if (bucket.maxSelection < bucket.minSelection) {
          //   setSubmitError(
          //     "Max Selection must be greater than min selection for all buckets",
          //   );
          //   bucketErrorExists = true;
          //   return;
        } else if (bucket.minSelection > bucket.preexistingMatchupIds.length) {
          setSubmitError(
            "Buckets cannot have more required selections than they do matchups",
          );
          bucketErrorExists = true;
          return;
        }
      });

    if (bucketErrorExists) {
      return;
    }

    setInProgress(true);
    createContestTemplate({
      variables: {
        name,
        description,
        viewableStartsAtUtc,
        availableStartsAtUtc,
        availableEndsAtUtc,
        minContestants: !!format1v1 ? 2 : 3,
        maxContestants: !!formatGroup ? 8 : 2,
        maxContests: Number.parseInt(maxContests),
        tokenEntry,
        freeEntry,
        // TODO: reimplement maxTokenEntry
        minTokenEntry: !!minTokenEntry
          ? Number.parseInt(minTokenEntry)
          : undefined,
        maxTokenEntry: !!minTokenEntry
          ? Number.parseInt(minTokenEntry)
          : undefined,
        rewardId:
          !!tokenEntry && !!rewardId ? Number.parseInt(rewardId) : undefined,
        sponsorId: !!sponsorId ? Number.parseInt(sponsorId) : undefined,
        sport,
        buckets: bucketsArray.map((bucket) => ({
          name: bucket.name,
          description: bucket.description,
          selectionWindowStartsAtUtc: bucket.selectionWindowStartsAtUtc,
          selectionWindowEndsAtUtc: bucket.selectionWindowEndsAtUtc,
          minSelection: bucket.minSelection,
          maxSelection: bucket.minSelection,
          preexistingMatchupIds: bucket.preexistingMatchupIds,
          newMatchups: [],
        })),
      },
    })
      .then((res) => {
        setInProgress(false);
        setName("");
        setDescription("");
        setViewableStartsAtUtc(null);
        setAvailableStartsAtUtc(null);
        setAvailableEndsAtUtc(null);
        setFormat1v1(false);
        setFormatGroup(false);
        setMaxContests("");
        setTokenEntry(false);
        setFreeEntry(false);
        setMinTokenEntry("");
        setRewardId("");
        setSponsorId("");
        setSport("");
        localDispatch({ type: "RESET_BUCKET_STATE" });
        toast.success("Success!");
      })
      .catch((e) => {
        toast.error(JSON.parse(JSON.stringify(e)).message);
        setInProgress(false);
        console.log("e", e);
      });
  };

  const preFill = () => {
    setName("NFL Week 6 Challenge");
    setDescription("Lorem Ipsum dolor sit amet yada yada");
    setFormat1v1(true);
    setFormatGroup(true);
    setFreeEntry(true);
    setTokenEntry(true);
    setMinTokenEntry("20");
    // setMaxTokenEntry("25");
    setViewableStartsAtUtc(new Date("2024-09-16T16:24:18.755Z"));
    setAvailableStartsAtUtc(new Date("2024-09-20T16:24:18.755Z"));
    setAvailableEndsAtUtc(new Date("2024-09-30T16:24:18.755Z"));
  };

  return (
    <div className={style.main}>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Details" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Details")}
        >
          Details
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Buckets" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Buckets")}
        >
          Buckets
        </div>
      </div>
      <div style={{ display: activeTab === "Details" ? "block" : "none" }}>
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={viewableStartsAtUtc}
          onChange={(date) => setViewableStartsAtUtc(date)}
          label="Contest Template is Viewable Starting at"
          error={viewableStartsAtUtcError}
        />
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={availableStartsAtUtc}
          onChange={(date) => setAvailableStartsAtUtc(date)}
          label="Contest Template is Available Starting at"
          error={availableStartsAtUtcError}
        />
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={availableEndsAtUtc}
          onChange={(date) => setAvailableEndsAtUtc(date)}
          label="Contest Template is Available Ending at"
          error={availableEndsAtUtcError}
        />
        <Components.TextInput
          className={style.textInput}
          value={name}
          onChange={setName}
          label="Name"
          error={nameError}
          autocomplete="off"
        />
        <Components.TextInput
          className={style.textInput}
          value={description}
          onChange={setDescription}
          label="Description"
          error={descriptionError}
        />
        {/* <Components.TextInput
          className={style.textInput}
          value={minContestants}
          onChange={setMinContestants}
          label="Min Contestants"
          inputType="number"
          error={minContestantsError}
        />
        <Components.TextInput
          className={style.textInput}
          value={maxContestants}
          onChange={setMaxContestants}
          label="Max Contestants"
          inputType="number"
          error={maxContestantsError}
        /> */}
        <Components.TextInput
          className={style.textInput}
          value={maxContests}
          onChange={setMaxContests}
          label="Max Contests"
          inputType="number"
          error={maxContestsError}
        />
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={sponsorId}
            onChange={(e) => setSponsorId(e.target.value as string)}
            autoWidth={true}
            label="Sponsor"
          >
            {brandsArray.map((brand) => (
              <MenuItem value={brand.id} key={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
          <div>Sponsor (Optional)</div>
          <div
            onClick={() => setSponsorId("")}
            style={{
              padding: 8,
              backgroundColor: "lightblue",
              cursor: "pointer",
            }}
          >
            Clear
          </div>
        </div>{" "}
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={sport}
            onChange={(e) => setSport(e.target.value as string)}
            autoWidth={true}
            label="Sport"
          >
            {Object.values(Sport).map((sport) => (
              <MenuItem value={sport} key={sport}>
                {sport}
              </MenuItem>
            ))}
          </Select>
          <div>Sport (Optional)</div>
          <div
            onClick={() => setSport("")}
            style={{
              padding: 8,
              backgroundColor: "lightblue",
              cursor: "pointer",
            }}
          >
            Clear
          </div>
        </div>
        <div className={style.checkboxRow}>
          <Checkbox
            checked={format1v1}
            onChange={(e) => setFormat1v1(e.target.checked)}
          />
          <div>1v1 Contests</div>
        </div>{" "}
        <div className={style.checkboxRow}>
          <Checkbox
            checked={formatGroup}
            onChange={(e) => setFormatGroup(e.target.checked)}
          />
          <div>Group Contests</div>
        </div>
        <div className={style.checkboxRow}>
          <Checkbox
            checked={freeEntry}
            onChange={(e) => setFreeEntry(e.target.checked)}
          />
          <div>Free Entry</div>
        </div>{" "}
        <div className={style.checkboxRow}>
          <Checkbox
            checked={tokenEntry}
            onChange={(e) => setTokenEntry(e.target.checked)}
          />
          <div>Token Entry</div>
        </div>
        {tokenEntry && (
          <>
            <Components.TextInput
              className={style.textInput}
              value={minTokenEntry}
              onChange={setMinTokenEntry}
              label="Token Entry"
              inputType="number"
              error={minTokenEntryError}
            />
            {/* <Components.TextInput
              className={style.textInput}
              value={maxTokenEntry}
              onChange={setMaxTokenEntry}
              label="Max Token Entry"
              inputType="number"
              error={maxTokenEntryError}
            /> */}
            <div
              style={{
                marginBottom: 12,
                display: "flex",
                gap: 12,
                alignItems: "center",
              }}
            >
              <Select
                multiple={false}
                value={rewardId}
                onChange={(e) => setRewardId(e.target.value as string)}
                autoWidth={true}
                label="Reward"
              >
                {rewardsArray.map((reward) => (
                  <MenuItem value={reward.id} key={reward.id}>
                    {reward.name}
                  </MenuItem>
                ))}
              </Select>
              <div>Reward</div>
            </div>
          </>
        )}
      </div>
      <div style={{ display: activeTab === "Buckets" ? "block" : "none" }}>
        <CreateBuckets state={bucketsState} localDispatch={localDispatch} />
      </div>
      <div style={{ display: activeTab === "Matchups" ? "block" : "none" }}>
        <CreateMatchups />
      </div>
      <div
        style={{ display: activeTab === "MatchupOptions" ? "block" : "none" }}
      >
        <CreateMatchupOptions />
      </div>
      {(activeTab === "Buckets" || activeTab === "Details") && (
        <Components.Button
          className={style.submit}
          label={"Submit"}
          onClick={onSubmit}
          inProgress={inProgress}
        />
      )}
      {submitError && <div className={style.error}>{submitError}</div>}
      <Components.Button label={"Pre Fill Form"} onClick={preFill} />
    </div>
  );
};

export const CreateContestTemplate = connect((state: RootState) => ({
  rewards: state.rewards.rewards.items,
  brands: state.rewards.brands.items,
}))(CreateContestTemplateInternal);
